


import Chart from 'chart.js/auto';
import { READY, cleanDB, getAllTours, getGPX, getUser, sport_icons } from './komootAPI';
import {
    round, length, nan_to_zero,
    to_string_group_by_3, zeroPad,
    add_event_listener_to_select, date_overlaps, add_event_listener_to_multi_select, hours_to_hhmm, date_to_ddmmyyyy, ratio, getsign
} from './utils';

import { Map } from 'maplibre-gl';



const kfont = require("../assets/fonts/kfont.ttf")
const font = new FontFace("kmt-font", "url(." + kfont + ")", {});
document.fonts.add(font);

const black = "#484848"

class app {
    tours: any = {};
    chart30d: Chart;
    chart30d_state: number = 0;
    chart12m: Chart;
    chart12m_state: number = 0;
    chartW: Chart;
    chartW_state: number = 0;
    chartP: Chart;
    chartP_state: number = 0;
    chartSport: Chart;
    chartSport_state: number = 0;
    komoot_id: string = "";
    sports: string[] = [];
    sport_filters: string[] = [];

    resize_timeout: any;
    tour_list_opened = false;
    tour_list_limit = 20;

    rotation = 0;
    map: Map;
    first_pos = true;



    async init() {
        document.getElementById("username").setAttribute("class", "loading");
        var new_tour = {};
        var res = await getAllTours(this.komoot_id, new_tour);
        if (res === undefined) {
            document.getElementById("username").setAttribute("class", "info");
        }
        else if (res === false) {
            document.getElementById("username").setAttribute("class", "error");
        }
        else {
            document.getElementById("username").setAttribute("class", "valid");
            this.tours = new_tour;
        }
        this.update_user();
        this.filter_invalid_tours();

        this.sports = this.list_sports(this.tours);

        this.update_filters();
        this.update_general_stats();

        this.update_tour_display()
        this.gen30DChart(this.chart30d_state);
        this.gen12MChart(this.chart12m_state);
        this.genWChart(this.chartW_state);
        this.genPChart(this.chartP_state);
        this.genSportChart(this.chartSport_state);

        add_event_listener_to_select("chart30day-select", (selected) => {
            this.chart30d_state = selected;
            this.gen30DChart(this.chart30d_state);
        });

        add_event_listener_to_select("chart12month-select", (selected) => {
            this.chart12m_state = selected;
            this.gen12MChart(this.chart12m_state);
        });

        add_event_listener_to_select("chartWeek-select", (selected) => {
            this.chartW_state = selected;
            this.genWChart(this.chartW_state);
        });

        add_event_listener_to_select("chartProgress-select", (selected) => {
            this.chartP_state = selected;
            this.genPChart(this.chartP_state);
        });

        add_event_listener_to_select("chartSport-select", (selected) => {
            this.chartSport_state = selected;
            this.genSportChart(this.chartSport_state);
        });

        add_event_listener_to_multi_select("filter-sports", (state) => {
            this.sport_filters = [];
            for (let i = 0; i < this.sports.length; i++) {
                if (state[i]) {
                    this.sport_filters.push(this.sports[i])
                }
            }
            this.gen30DChart(this.chart30d_state);
            this.gen12MChart(this.chart12m_state);
            this.genWChart(this.chartW_state);
            this.genPChart(this.chartP_state);
            this.download_each_tour();
            this.update_tour_display()
            this.update_general_stats();

        });
        if (this.map == undefined) {
            this.map = new Map({
                container: 'map',
                style: 'https://tiles-api.maps.komoot.net/v1/style.json',
                center: [0, 0],
                zoom: 0
            });

            this.map.on('load', () => {
                this.map.addLayer({
                    "id": "route",
                    "type": "line",
                    "source": {
                        "type": "geojson",
                        "data": {
                            "type": "MultiLineString",
                            "coordinates": []
                        }
                    },
                    "layout": {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    "paint": {
                        "line-color": "#0077D9CC",
                        "line-width": 2
                    }
                });

                setTimeout(() => {
                    this.download_each_tour();
                }, 1000);
            });

        }
        else {
            var interval = setInterval(() => {
                if (READY) {
                    this.download_each_tour();
                    clearInterval(interval);
                }
            }, 100);

        }

    }

    async update_user() {
        var user = await getUser(this.komoot_id);

        if (user != undefined) {
            document.getElementById("profile-pic").style.display = "block";
            document.getElementById("profile-pic").setAttribute("src", user["avatar"]);
            document.getElementById("profile-name").style.display = "block";
            document.getElementById("profile-name").innerHTML = user["name"];
            document.getElementById("profile-name").style.color = "black";
            document.getElementById("profile").setAttribute("href", "https://www.komoot.fr/user/" + user["id"])
            
        }
        else {
            document.getElementById("profile-pic").style.display = "none";
            if (this.komoot_id == ""){
                document.getElementById("profile-name").style.display = "none";
            }
            else{   
                document.getElementById("profile-name").style.display = "block";
                document.getElementById("profile-name").innerHTML = "Utilisateur inconnu";
                document.getElementById("profile-name").style.color = "red";
            }
            document.getElementById("profile").setAttribute("href", "");
        }
    }



    update_filters() {
        var filter_container = document.getElementById("filter-sports");
        filter_container.innerHTML = "";
        for (let i = 0; i < this.sports.length; i++) {
            var sport = this.sports[i];
            var icon = String.fromCharCode(sport_icons[sport]);
            var a = document.createElement("a");
            a.innerHTML = icon;
            a.classList.add("icon");
            filter_container.appendChild(a);
        }
    }

    update_general_stats() {
        var tours = this.filter_on_sports(this.tours, this.sport_filters);


        document.getElementById("nb-tours").innerHTML = this.number_of_tours(tours).toString();

        var tot_time = this.total_duration(tours);
        var tot_time_h = Math.floor(tot_time);
        var tot_time_m = Math.floor((tot_time - tot_time_h) * 60);

        document.getElementById("tot-time-h").innerHTML = zeroPad(tot_time_h, 2);
        document.getElementById("tot-time-m").innerHTML = zeroPad(tot_time_m, 2);

        var tot_dist = this.total_distance(tours);
        document.getElementById("tot-dist").innerHTML = round(tot_dist.toFixed(2), 1).toString();

        var tot_uphill = this.total_elevation_up(tours);
        document.getElementById("tot-uphill").innerHTML = to_string_group_by_3(round(tot_uphill, 0)).toString();

        var tot_downhill = this.total_elevation_down(tours);
        document.getElementById("tot-downhill").innerHTML = to_string_group_by_3(round(tot_downhill, 0)).toString();

        if (!this.chart30d) {
            this.chart30d = new Chart(document.getElementById('chart30day'), {});
            this.chart12m = new Chart(document.getElementById('chart12month'), {});
            this.chartW = new Chart(document.getElementById('chartWeek'), {});
            this.chartP = new Chart(document.getElementById('chartProgress'), {});
            this.chartSport = new Chart(document.getElementById('chartSport'), {});
        }
    }

    ////////////////////////////////////////////////////////////////////////////////
    // tours list
    /////////////////////////////////////////////////////////////////////////////////

    hideTours() {

        this.tour_list_opened = false;
        document.getElementById("tour-list").innerHTML = "";
        document.getElementById("show-tours-icon").innerHTML = "expand_more"
        this.update_tour_display();
        this.tour_list_limit = 20;
    }

    showTours() {
        this.tour_list_opened = true;
        document.getElementById("tour-list").innerHTML = "";
        document.getElementById("show-tours-icon").innerHTML = "expand_less"
        this.update_tour_display();
    }

    update_tour_display() {
        var tours = this.filter_on_sports(this.tours, this.sport_filters);

        document.getElementById("tour-list").innerHTML = "";
        var keys = Object.keys(tours).reverse();
        var num = this.tour_list_limit;
        if (!this.tour_list_opened) num = 1
        var nb_tours = keys.length;
        if (this.tour_list_opened && nb_tours <= 1) {
            this.hideTours();
            return;
        }
        keys = keys.slice(0, num);
        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            var html = document.createElement("a");
            html.className = "tour clickable";
            html.innerHTML = `
                <img src="${tours[key]["img"]}" alt="tour preview">
                <div>
                    <div>
                        <h3>${tours[key]["name"]}</h3>
                        <div class="stats">
                            <div>
                                <span class="icon icon-time"></span> 
                                <span class="value">${hours_to_hhmm(tours[key]["time_in_motion"].toFixed(1))}</span>
                            </div>
                            <div>
                                <span class="icon icon-distance"></span> 
                                <span class="value">${tours[key]["distance"].toFixed(1)}</span>
                                <span class="unit">km</span>
                            </div>
                            <div>
                                <span class="icon icon-speed"></span> 
                                <span class="value">${tours[key]["mean_speed"].toFixed(1)}</span>
                                <span class="unit">km/h</span>
                            </div>
                            <div>
                                <span class="icon icon-uphill"></span> 
                                <span class="value">${tours[key]["elevation_up"].toFixed(1)}</span>
                                <span class="unit">m</span>
                            </div>
                            <div>
                                <span class="icon icon-downhill"></span> 
                                <span class="value">${tours[key]["elevation_down"].toFixed(1)}</span>
                                <span class="unit">m</span>
                            </div>
                        </div>
                    </div>
                    <span>${date_to_ddmmyyyy(tours[key]["date"])}</span>
                </div>
            `

            var forward = document.createElement("span");
            forward.className = "material-symbols-outlined";
            forward.innerHTML = "arrow_forward_ios";

            html.setAttribute("tour-id", tours[key]["id"])
            // html.onclick = (e) => {
            //     var target:HTMLElement = e.target;
            //     while (target.className != "tour clickable") {
            //         target = target.parentElement;
            //     }
            //     var id = target.getAttribute("tour-id");
            //     // open page :

            //     this.openTour(id);
            // };
            html.setAttribute("href", "/tour.html?id=" + tours[key]["id"])
            html.appendChild(forward);

            document.getElementById("tour-list").appendChild(html);

            var small_line = document.createElement("div");
            small_line.className = "small-line";
            document.getElementById("tour-list").appendChild(small_line);
        }
        if (nb_tours > 1) {
            var show_more = document.createElement("div");
            show_more.className = "clickable material-symbols-outlined expand";
            show_more.innerHTML = (num >= nb_tours) ? "remove" : "expand_more";
            if (num < nb_tours) {
                show_more.onclick = () => {
                    if (this.tour_list_opened) {
                        this.tour_list_limit += 20;
                        this.update_tour_display();
                    }
                    else
                        this.showTours();

                };
            }
            else {
                show_more.onclick = () => {
                    this.hideTours();
                };
            }
            document.getElementById("tour-list").appendChild(show_more);
        }

    }

    /////////////////////////////////////////////////////////////////////////////////
    // Chart generation
    /////////////////////////////////////////////////////////////////////////////////

    gen30DChart(type) {
        this.chart30d_state = type;
        var tours = this.filter_on_sports(this.tours, this.sport_filters);


        var i = 0
        const DISTANCE = (i++), DURATION = (i++), SPEED = (i++), ELEVATION_UP = (i++), ELEVATION_DOWN = (i++), COUNT = (i++);
        const DIGITS = [1, 0, 1, 0, 0, 1]
        const units = ["km", "h", "km/h", "m", "m", ""]
        var today = new Date();
        var month = new Date(today.getFullYear(), today.getMonth(), 1);
        var last_month = new Date(month.getFullYear(), month.getMonth() - 1, 1);
        while (last_month.getDay() != month.getDay()) {
            last_month = new Date(last_month.getTime() + 24 * 60 * 60 * 1000);
        }

        var tours_this_month = this.filter_after(tours, month);
        var tours_last_month = this.filter_between(tours, last_month, month);

        if (length(tours_this_month) == 0 && length(tours_last_month) == 0) {
            document.getElementById("chart30day-noData").style.display = "block";
        }
        else {
            document.getElementById("chart30day-noData").style.display = "none";
        }


        var t_x = []
        var t_stat = []
        var t_stat_last = []
        var t_week_ends = []
        var mean_tot = [];
        var mean_tot_last = [];

        for (let i = 1; i <= 31; i++) {
            var date = new Date(today.getFullYear(), today.getMonth(), i);
            t_x.push(new Date(date).toLocaleDateString('fr-FR'));
            t_stat.push([-10, 0]);
            t_stat_last.push([-10, 0]);
            mean_tot.push(0);
            mean_tot_last.push(0);

            if (date.getDay() == 0) {
                t_week_ends.push(i);
            }
        }

        var max = 0;
        for (var key in tours_this_month) {
            var i = Math.floor((tours_this_month[key]["date"].getTime() - month.getTime()) / (24 * 60 * 60 * 1000));


            if (type == DISTANCE)
                t_stat[i][1] += tours_this_month[key]["distance"];
            else if (type == DURATION)
                t_stat[i][1] += tours_this_month[key]["duration"];
            else if (type == SPEED) {
                t_stat[i][1] += tours_this_month[key]["distance"];
                mean_tot[i] += tours_this_month[key]["time_in_motion"];
            }
            else if (type == ELEVATION_UP)
                t_stat[i][1] += tours_this_month[key]["elevation_up"];
            else if (type == ELEVATION_DOWN)
                t_stat[i][1] += tours_this_month[key]["elevation_down"];
            else if (type == COUNT)
                t_stat[i][1] += 1;

        }

        for (var key in tours_last_month) {
            var i = Math.floor((tours_last_month[key]["date"].getTime() - last_month.getTime()) / (24 * 60 * 60 * 1000));
            if (type == DISTANCE)
                t_stat_last[i][1] += tours_last_month[key]["distance"];
            else if (type == DURATION)
                t_stat_last[i][1] += tours_last_month[key]["duration"];
            else if (type == SPEED) {
                t_stat_last[i][1] += tours_last_month[key]["distance"];
                mean_tot_last[i] += tours_last_month[key]["time_in_motion"];
            }
            else if (type == ELEVATION_UP)
                t_stat_last[i][1] += tours_last_month[key]["elevation_up"];
            else if (type == ELEVATION_DOWN)
                t_stat_last[i][1] += tours_last_month[key]["elevation_down"];
            else if (type == COUNT)
                t_stat_last[i][1] += 1;
        }

        if (type == SPEED) {
            for (let i = 0; i < t_stat.length; i++) {
                if (mean_tot[i] > 0) {
                    t_stat[i][1] /= mean_tot[i];
                }
                if (mean_tot_last[i] > 0) {
                    t_stat_last[i][1] /= mean_tot_last[i];
                }
            }
        }

        for (let i = 0; i < t_stat.length; i++) {
            if (t_stat[i][1] > max) {
                max = t_stat[i][1];
            }
            if (t_stat_last[i][1] > max) {
                max = t_stat_last[i][1];
            }
        }

        for (let i = 0; i < t_stat.length; i++) {
            t_stat[i][0] = -(max / 10)
            t_stat_last[i][0] = -(max / 10)
        }


        this.chart30d.data = {
            labels: t_x,
            datasets: [
                {
                    type: 'bar',
                    label: 'Ce mois-ci',
                    data: t_stat,
                    backgroundColor: '#9CDE4ECC',
                    borderColor: '#4F850D',
                    borderWidth: 1
                },
                {
                    type: 'bar',
                    label: 'Le mois dernier',
                    data: t_stat_last,
                    backgroundColor: '#cccccc66',
                    borderColor: '#aaaaaa',
                    borderWidth: 1
                },
                {
                    type: 'line',
                    label: 'Aujourd\'hui',
                    borderColor: '#63b15b',
                    backgroundColor: '#0000',
                    borderDash: [5, 5],
                    indexAxis: 'y',
                    data: [{ x: today.getDate() + month.getDay() - 1 - 0.5, y: 0 }, { x: today.getDate() + month.getDay() - 1 - 0.5, y: max }],
                    xAxisID: 'x2',
                    pointRadius: 0,
                }
            ]
        }
        var parent = this.chart30d.canvas.parentNode;
        var font_size = parent.clientWidth / 50;

        this.chart30d.options = {
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        font: {
                            size: font_size,
                        }
                    }
                },
                tooltip: {
                    enabled: false
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    min: -(max / 100),
                    max: max,
                    ticks: {
                        callback: function (val, index,) {
                            return ((val >= 0 ? round(val, DIGITS[type]) : 0) + " " + units[type]);
                        },
                        font: {
                            size: font_size,
                        },
                    },
                },
                x: {
                    ticks: {
                        callback: function (val, index,) {
                            return index % 4 === 0 ? t_x[val] : '';
                        },
                        font: {
                            size: font_size,
                        },
                    },
                    grid: {
                        // show grid for each individual bar
                        drawOnChartArea: false
                    },
                    stacked: true,
                },
                x2: {
                    position: 'top',
                    type: 'linear',
                    ticks: {
                        callback: function (val, index,) {
                            return ""
                        },
                        font: {
                            size: font_size,
                        },
                        stepSize: 7,
                    },
                    // display: false,
                    min: 0 + month.getDay() - 1,
                    max: 31 + month.getDay() - 1,
                }
            },
            maintainAspectRatio: false,
            animation: {
                duration: 0
            }
        }
        if (this.resize_timeout) clearTimeout(this.resize_timeout)
        this.resize_timeout = setTimeout(() => { resize(); }, 200);
        this.chart30d.resize();
        this.chart30d.update();


        // some stats
        var count = this.number_of_tours(tours_this_month);
        var count_last = this.number_of_tours(tours_last_month);
        var tot_time = this.total_time_in_motion(tours_this_month);
        var tot_time_last = this.total_time_in_motion(tours_last_month);
        var tot_dist = this.total_distance(tours_this_month);
        var tot_dist_last = this.total_distance(tours_last_month);
        var tot_uphill = this.total_elevation_up(tours_this_month);
        var tot_uphill_last = this.total_elevation_up(tours_last_month);
        var mean_speed = nan_to_zero(tot_dist / tot_time);
        var mean_speed_last = nan_to_zero(tot_dist_last / tot_time_last);
        var mean_dist = nan_to_zero(tot_dist / length(tours_this_month));
        var mean_dist_last = nan_to_zero(tot_dist_last / length(tours_last_month));
        var mean_time = nan_to_zero(tot_time / length(tours_this_month));
        var mean_time_last = nan_to_zero(tot_time_last / length(tours_last_month));

        var better = "#63b15b"
        var zero = "#B89B4F"
        var worse = "#D64040"

        var getcolor = (val) => {
            if (val == 0) return zero;
            return val > 0 ? better : worse;
        }

        document.getElementById("nb-tours-month").innerHTML = count.toString();
        document.getElementById("nb-tours-month-last").innerHTML = count_last.toString();
        var sign = getsign(count - count_last);
        document.getElementById("nb-tours-month-evol").innerHTML = sign + Math.abs(count - count_last).toString();
        document.getElementById("nb-tours-month-evol").style.color = getcolor(count - count_last);

        document.getElementById("tot-time").innerHTML = hours_to_hhmm(tot_time.toFixed(1));
        document.getElementById("tot-time-last").innerHTML = hours_to_hhmm(tot_time_last.toFixed(1));
        var sign = getsign(tot_time - tot_time_last);
        document.getElementById("tot-time-evol").innerHTML = sign + hours_to_hhmm(Math.abs(tot_time - tot_time_last).toFixed(1));
        document.getElementById("tot-time-evol").style.color = getcolor(tot_time - tot_time_last);

        document.getElementById("tot-distance").innerHTML = round(tot_dist, 1).toString();
        document.getElementById("tot-distance-last").innerHTML = round(tot_dist_last, 1).toString();
        sign = getsign(tot_dist - tot_dist_last);
        document.getElementById("tot-distance-evol").innerHTML = sign + round(Math.abs(tot_dist - tot_dist_last), 1).toString();
        document.getElementById("tot-distance-evol").style.color = getcolor(tot_dist - tot_dist_last);

        document.getElementById("tot-elev").innerHTML = to_string_group_by_3(round(tot_uphill, 0)).toString();
        document.getElementById("tot-elev-last").innerHTML = to_string_group_by_3(round(tot_uphill_last, 0)).toString();
        sign = getsign(tot_uphill - tot_uphill_last);
        document.getElementById("tot-elev-evol").innerHTML = sign + to_string_group_by_3(round(Math.abs(tot_uphill - tot_uphill_last), 0)).toString();
        document.getElementById("tot-elev-evol").style.color = getcolor(tot_uphill - tot_uphill_last);

        document.getElementById("mean-speed").innerHTML = round(mean_speed, 1).toString();
        document.getElementById("mean-speed-last").innerHTML = round(mean_speed_last, 1).toString();
        sign = getsign(mean_speed - mean_speed_last);
        document.getElementById("mean-speed-evol").innerHTML = sign + round(Math.abs(mean_speed - mean_speed_last), 1).toString();
        document.getElementById("mean-speed-evol").style.color = getcolor(mean_speed - mean_speed_last);

        document.getElementById("mean-dist").innerHTML = round(mean_dist, 1).toString();
        document.getElementById("mean-dist-last").innerHTML = round(mean_dist_last, 1).toString();
        sign = getsign(mean_dist - mean_dist_last);
        document.getElementById("mean-dist-evol").innerHTML = sign + round(Math.abs(mean_dist - mean_dist_last), 1).toString();
        document.getElementById("mean-dist-evol").style.color = getcolor(mean_dist - mean_dist_last);

        document.getElementById("mean-time").innerHTML = hours_to_hhmm(mean_time.toFixed(1));
        document.getElementById("mean-time-last").innerHTML = hours_to_hhmm(mean_time_last.toFixed(1));
        sign = getsign(mean_time - mean_time_last);
        document.getElementById("mean-time-evol").innerHTML = sign + hours_to_hhmm(Math.abs(mean_time - mean_time_last).toFixed(1));
        document.getElementById("mean-time-evol").style.color = getcolor(mean_time - mean_time_last);
    }

    gen12MChart(type) {
        this.chart12m_state = type;
        var tours = this.filter_on_sports(this.tours, this.sport_filters);

        var i = 0
        const DISTANCE = (i++), DURATION = (i++), SPEED = (i++), ELEVATION_UP = (i++), ELEVATION_DOWN = (i++), COUNT = (i++);
        const DIGITS = [1, 0, 1, 0, 0, 1]
        const units = ["km", "h", "km/h", "m", "m", ""]
        var today = new Date();
        var year = new Date(today.getFullYear() - 1, today.getMonth() + 1, 1);

        var tours_12m = this.filter_after(tours, year);

        if (length(tours_12m) == 0)
            document.getElementById("chart12month-noData").style.display = "block";
        else
            document.getElementById("chart12month-noData").style.display = "none";


        var t_x = []
        var t_stat = []
        var mean_tot = [];
        var t_stat_mean = []

        for (let i = 0; i < 12; i++) {
            var date = new Date(today.getFullYear(), today.getMonth() - i, 1);
            t_x.push(zeroPad(date.getMonth() + 1, 2) + "/" + (date.getFullYear() - 2000));
            t_stat.push([-10, 0]);
            t_stat_mean.push(0);
            mean_tot.push(0);
        }

        var max = 0;

        for (var key in tours_12m) {
            var month = tours_12m[key]["date"].getMonth();

            var i = today.getMonth() - month;
            if (i < 0) i += 12;

            if (type == DISTANCE)
                t_stat[i][1] += tours_12m[key]["distance"];
            else if (type == DURATION)
                t_stat[i][1] += tours_12m[key]["duration"];
            else if (type == SPEED) {
                t_stat[i][1] += tours_12m[key]["distance"];
                mean_tot[i] += tours_12m[key]["time_in_motion"];
            }
            else if (type == ELEVATION_UP)
                t_stat[i][1] += tours_12m[key]["elevation_up"];
            else if (type == ELEVATION_DOWN)
                t_stat[i][1] += tours_12m[key]["elevation_down"];
            else if (type == COUNT)
                t_stat[i][1] += 1;
        }
        if (type == SPEED) {
            for (let i = 0; i < t_stat.length; i++) {
                if (mean_tot[i] > 0) {
                    t_stat[i][1] /= mean_tot[i];
                }
            }
        }

        for (let i = 0; i < t_stat.length; i++) {
            if (t_stat[i][1] > max) {
                max = t_stat[i][1];
            }
        }

        var month_year = {}
        var month_year_tot = {}
        for (var key in tours) {
            var month = tours[key]["date"].getMonth();
            let year_ = tours[key]["date"].getFullYear();

            if (!month_year[month]) {
                month_year[month] = {}
                month_year_tot[month] = {}
            }
            if (!month_year[month][year_]) {
                month_year[month][year_] = 0
                month_year_tot[month][year_] = 0
            }

            if (type == DISTANCE)
                month_year[month][year_] += tours[key]["distance"];
            else if (type == DURATION)
                month_year[month][year_] += tours[key]["duration"];
            else if (type == SPEED) {
                month_year[month][year_] += tours[key]["distance"];
                month_year_tot[month][year_] += tours[key]["time_in_motion"];
            }
            else if (type == ELEVATION_UP)
                month_year[month][year_] += tours[key]["elevation_up"];
            else if (type == ELEVATION_DOWN)
                month_year[month][year_] += tours[key]["elevation_down"];
            else if (type == COUNT)
                month_year[month][year_] += 1;
        }

        if (type == SPEED) {

            for (var month in month_year) {
                var i = today.getMonth() - parseInt(month);
                if (i < 0) i += 12;

                var mean = 0;
                var tot = 0;
                for (let year_ in month_year[month]) {
                    mean += month_year[month][year_]
                    tot += month_year_tot[month][year_]
                }

                mean /= tot;
                t_stat_mean[i] = mean;
                if (mean > max) max = mean;
            }
        }
        else {
            for (var month in month_year) {
                var i = today.getMonth() - parseInt(month);
                if (i < 0) i += 12;

                var mean = 0;
                for (let year_ in month_year[month]) {
                    mean += month_year[month][year_]
                }
                mean /= Object.keys(month_year[month]).length;
                t_stat_mean[i] = mean;
                if (mean > max) max = mean;
            }

        }




        var data_last = []
        var data_actu = []
        for (let i = 0; i < 12; i++) {
            t_stat[i][0] = -(max / 10)
            if (i == 0) {
                data_actu.push(t_stat[i]);
                data_last.push([-10, -10]);
            }
            else {
                data_actu.push([-10, -10]);
                data_last.push(t_stat[i]);
            }
        }

        this.chart12m.data = {
            labels: t_x,
            datasets: [
                {
                    type: 'line',
                    data: t_stat_mean,
                    borderColor: black,
                    backgroundColor: black,
                    borderDash: [5, 5],
                    z: 2,
                    // point size

                },
                {
                    type: "line",
                    label: 'En moyenne',
                    data: [],
                    borderColor: black,
                    backgroundColor: '#0000',
                    borderDash: [5, 5],
                },
                {
                    type: 'bar',
                    label: 'Cet année',
                    data: data_last,//t_stat.splice(0, 12),
                    backgroundColor: '#9CDE4E' + "CC",
                    borderColor: '#4F850D',
                    borderWidth: 2,
                    z: 1,
                },
                {
                    type: 'bar',
                    data: data_actu,
                    backgroundColor: '#63b3ed' + "CC",
                    borderColor: '#0077D9',
                    borderWidth: 2,
                    borderDash: [10, 5],
                    z: 1,
                },
            ]
        }
        var parent = this.chart12m.canvas.parentNode;
        var font_size = parent.clientWidth / 50;

        this.chart12m.options = {
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        font: {
                            size: font_size,
                        },
                        filter: function (item, chart) {
                            return item.text != undefined;
                        },
                    },
                    reverse: true,
                },
                tooltip: {
                    enabled: true,
                    callbacks: {
                        label: function (context) {
                            if (context.datasetIndex == 0) {
                                return round(context.parsed.y, 1) + " " + units[type];
                            }
                            else {
                                return round(context.parsed._custom.end, 1) + " " + units[type];
                            }
                        }
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    min: -(max / 100),
                    max: max,
                    ticks: {
                        callback: function (val, index,) {
                            return (val >= 0 ? round(val, DIGITS[type]) : 0) + " " + units[type];
                        },
                        font: {
                            size: font_size,
                        },
                    },
                },
                x: {
                    ticks: {
                        callback: function (val, index,) {
                            return t_x[val];
                        },
                        font: {
                            size: font_size,
                        },
                    },
                    grid: {
                        // show grid for each individual bar
                        drawOnChartArea: false
                    },
                    stacked: true,
                    reverse: true,
                }
            },
            maintainAspectRatio: false,
            animation: {
                // onComplete: () =>{ setTimeout(()=>{resize();}, 300) },
                duration: 0
            }
        }
        if (this.resize_timeout) clearTimeout(this.resize_timeout)
        this.resize_timeout = setTimeout(() => { resize(); }, 200);
        this.chart12m.resize();
        this.chart12m.update();
    }

    genWChart(type) {
        this.chartW_state = type;
        var tours = this.filter_on_sports(this.tours, this.sport_filters);

        var i = 0
        const DISTANCE = (i++), DURATION = (i++), SPEED = (i++), ELEVATION_UP = (i++), ELEVATION_DOWN = (i++), COUNT = (i++);
        const DIGITS = [1, 0, 1, 0, 0, 1]
        const units = ["km", "h", "km/h", "m", "m", ""]
        const DAYS = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"]


        if (length(tours) == 0)
            document.getElementById("chartWeek-noData").style.display = "block";
        else
            document.getElementById("chartWeek-noData").style.display = "none";


        var t_x = []
        var t_stat = []
        var t_stat_tot = []
        var counts = []

        for (let i = 0; i < 7; i++) {
            t_x.push(DAYS[i]);
            t_stat.push([-10, 0]);
            t_stat_tot.push(0);
            counts.push(0);
        }

        var weeks = {}
        for (var key in tours) {
            var date = tours[key]["date"];
            var monday = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1);
            var str_rep = monday.getFullYear() + zeroPad(monday.getMonth() + 1, 2) + zeroPad(monday.getDate(), 2);
            if (!weeks[str_rep]) {
                weeks[str_rep] = []
            }
            weeks[str_rep].push(tours[key]);
        }
        var tours_of_dimanche = {}
        for (var key in weeks) {
            var toursofweek = weeks[key];
            var have_tour = [false, false, false, false, false, false, false]
            var state_of_week = [0, 0, 0, 0, 0, 0, 0]
            var state_of_week_tot = [0, 0, 0, 0, 0, 0, 0]
            for (let i = 0; i < toursofweek.length; i++) {
                var day = toursofweek[i]["date"].getDay();
                if (day == 0) {
                    if (!tours_of_dimanche[key]) {
                        tours_of_dimanche[key] = 0
                    }
                    tours_of_dimanche[key] += toursofweek[i]["distance"];
                }
                day = (day + 6) % 7;
                have_tour[day] = true;

                if (type == DISTANCE)
                    state_of_week[day] += toursofweek[i]["distance"];
                else if (type == DURATION)
                    state_of_week[day] += toursofweek[i]["duration"];
                else if (type == SPEED) {
                    state_of_week[day] += toursofweek[i]["distance"];
                    state_of_week_tot[day] += toursofweek[i]["time_in_motion"];
                }
                else if (type == ELEVATION_UP)
                    state_of_week[day] += toursofweek[i]["elevation_up"];
                else if (type == ELEVATION_DOWN)
                    state_of_week[day] += toursofweek[i]["elevation_down"];
                else if (type == COUNT)
                    state_of_week[day] += 1;
            }

            for (let i = 0; i < 7; i++) {
                if (state_of_week[i]) {
                    counts[i] += 1;
                }
            }
            for (let i = 0; i < 7; i++) {
                t_stat[i][1] += state_of_week[i];
                t_stat_tot[i] += state_of_week_tot[i];
            }
        }

        if (type == SPEED) {
            for (let i = 0; i < 7; i++) {
                if (t_stat[i][1] > 0) {
                    t_stat[i][1] /= t_stat_tot[i];
                    counts[i] = 1;
                }
            }
        }



        var max_count = 0;
        for (let i = 0; i < 7; i++) {
            if (counts[i] > max_count) {
                max_count = counts[i];
            }
        }

        var max = 0;
        for (let i = 0; i < 7; i++) {
            t_stat[i][1] /= max_count;

            if (t_stat[i][1] > max) {
                max = t_stat[i][1];
            }
        }

        for (let i = 0; i < 7; i++) {
            t_stat[i][0] = -(max / 10)
        }


        this.chartW.data = {
            labels: t_x,
            datasets: [
                {
                    type: 'bar',
                    data: t_stat,
                    backgroundColor: '#9CDE4E' + "CC",
                    borderColor: '#4F850D',
                    borderWidth: 2,
                },
            ]
        }
        var parent = this.chartW.canvas.parentNode;
        var font_size = parent.clientWidth / 50;

        this.chartW.options = {
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        font: {
                            size: font_size,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    callbacks: {
                        label: function (context) {

                            return round(context.parsed._custom.end, 1) + " " + units[type];
                        }
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    min: -(max / 100),
                    max: max,
                    ticks: {
                        callback: function (val, index,) {
                            return (val >= 0 ? round(val, DIGITS[type]) : 0) + " " + units[type];
                        },
                        font: {
                            size: font_size,
                        },
                    },
                },
                x: {
                    ticks: {
                        callback: function (val, index,) {
                            return t_x[val];
                        },
                        font: {
                            size: font_size,
                        },
                    },
                    grid: {
                        // show grid for each individual bar
                        drawOnChartArea: false
                    },
                }
            },
            maintainAspectRatio: false,
            animation: {
                duration: 0
            }
        }
        if (this.resize_timeout) clearTimeout(this.resize_timeout)
        this.resize_timeout = setTimeout(() => { resize(); }, 200);
        this.chartW.resize();
        this.chartW.update();
    }

    genPChart(type) {
        this.chartW_state = type;
        var tours = this.filter_on_sports(this.tours, this.sport_filters);

        var i = 0
        const DISTANCE = (i++), DURATION = (i++), SPEED = (i++), ELEVATION_UP = (i++), ELEVATION_DOWN = (i++)
        const DIGITS = [1, 0, 1, 0, 0, 1]
        const units = ["km", "h", "km/h", "m", "m", ""]
        const types = ["Distance", "Durée", "Vitesse", "Deniv. Positif", "Deniv. Négatif"]


        if (length(tours) == 0)
            document.getElementById("chartProgress-noData").style.display = "block";
        else
            document.getElementById("chartProgress-noData").style.display = "none";


        var t_x = []
        var t_stat = []
        var t_stat_tot = []
        var t_stat_mobile = []
        var t_max = []
        var max = 0;
        var min = 1000000;

        for (var key in tours) {
            t_x.push(tours[key]["date"].getTime());
            if (type == DISTANCE)
                t_stat.push(tours[key]["distance"]);
            else if (type == DURATION)
                t_stat.push(tours[key]["duration"]);
            else if (type == SPEED) {
                t_stat.push(tours[key]["distance"] / tours[key]["time_in_motion"]);

            }
            else if (type == ELEVATION_UP)
                t_stat.push(tours[key]["elevation_up"]);
            else if (type == ELEVATION_DOWN)
                t_stat.push(tours[key]["elevation_down"]);
            t_stat_mobile.push(0);
            if (t_stat[t_stat.length - 1] > max) {
                max = t_stat[t_stat.length - 1];
                t_max.push({ x: t_x[t_x.length - 1], y: max });
            }
            if (t_stat[t_stat.length - 1] < min) {
                min = t_stat[t_stat.length - 1];
            }
        }
        t_max.push({ x: t_x[t_x.length - 1], y: max });

        var range = max - min;
        max += range / 100;
        // min -= range / 100;
        // if (min < 0) min = 0;



        // compute mobile mean
        var WIN = Math.max(Math.round(t_stat.length / 10), 10);


        for (let i = 0; i < t_stat.length; i++) {
            var sum = 0;
            for (let j = 0; j < WIN; j++) {
                if (i - j >= 0) {
                    sum += t_stat[i - j];
                }
            }
            t_stat_mobile[i] = sum / Math.min(WIN, i + 1);
        }

        var datasets = [
            {
                type: 'line',
                data: t_stat,
                label: types[type],
                borderColor: '#8cc748',
                backgroundColor: '#0000',
                borderWidth: 1,
                pointRadius: 0,
                lineTension: 0
            }
        ]

        if (t_stat.length > 10) {
            datasets.push(
                {
                    type: 'line',
                    data: t_stat_mobile,
                    label: 'Progression',
                    borderColor: black,
                    backgroundColor: '#0000',
                    borderWidth: 3,
                    pointRadius: 0,
                    lineTension: 0.4
                }
            );

            datasets.push(
                {
                    type: 'line',
                    data: t_max,
                    label: 'Records',
                    borderColor: '#0077D9',
                    backgroundColor: '#0000',
                    borderWidth: 3,
                    pointRadius: 0,
                    lineTension: 0
                }
            );
        }

        // reverse dataset
        datasets = datasets.reverse();

        this.chartP.data = {
            labels: t_x,
            datasets: datasets
        }
        var parent = this.chartP.canvas.parentNode;
        var font_size = parent.clientWidth / 50;

        this.chartP.options = {
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        font: {
                            size: font_size,
                        }
                    },
                    reverse: true,
                },
                tooltip: {
                    enabled: true,
                    callbacks: {
                        label: function (context) {

                            return round(context.parsed._custom.end, 1) + " " + units[type];
                        }
                    }
                }
            },
            scales: {
                y: {
                    ticks: {
                        callback: function (val, index,) {
                            return (val >= 0 ? Math.round(val) : 0) + " " + units[type];
                        },
                        font: {
                            size: font_size,
                        },
                    },
                    min: min,
                    max: max,
                },
                x: {
                    ticks: {
                        callback: function (val, index,) {
                            return "";
                        },
                        font: {
                            size: font_size,
                        },
                    },
                    grid: {
                        // show grid for each individual bar
                        drawOnChartArea: false
                    },
                }
            },
            maintainAspectRatio: false,
            animation: {
                duration: 0
            }
        }
        if (this.resize_timeout) clearTimeout(this.resize_timeout)
        this.resize_timeout = setTimeout(() => { resize(); }, 200);
        this.chartP.resize();
        this.chartP.update();
    }

    genSportChart(type) {
        this.chartSport_state = type;
        var i = 0
        const DURATION = (i++), DISTANCE = (i++), COUNT = (i++);
        const DIGITS = [0, 1, 0]
        const units = ["h", "km", ""]

        var metrics = {}
        for (var key in this.tours) {
            if (!metrics[this.tours[key]["sport"]]) {
                metrics[this.tours[key]["sport"]] = 0
            }

            if (type == DURATION)
                metrics[this.tours[key]["sport"]] += this.tours[key]["duration"];
            else if (type == DISTANCE)
                metrics[this.tours[key]["sport"]] += this.tours[key]["distance"];
            else if (type == COUNT)
                metrics[this.tours[key]["sport"]] += 1;
        }

        if (length(metrics) == 0) {
            document.getElementById("chartSport-noData").style.display = "block";
        }
        else {
            document.getElementById("chartSport-noData").style.display = "none";
        }

        var metrics_sorted = []
        for (var key in metrics) {
            metrics_sorted.push([key, metrics[key]]);
        }
        metrics_sorted.sort(function (a, b) {
            return b[1] - a[1];
        });
        var keys = [], values = [];

        for (let i = 0; i < metrics_sorted.length; i++) {
            if (i < 3) {
                keys.push(metrics_sorted[i][0])
                values.push(metrics_sorted[i][1])
            }
            if (i == 3) {
                keys.push("...")
                values.push(0)
            }
            if (i >= 3) {
                values[3] += metrics_sorted[i][1]
            }
        }

        this.chartSport.data = {
            labels: keys,
            datasets: [
                {
                    type: 'bar',
                    data: values,
                    backgroundColor: '#9CDE4ECC',
                    borderColor: '#4F850D',
                    borderWidth: 2,
                    indexAxis: 'y',
                }
            ]
        }

        var parent = this.chartSport.canvas.parentNode;
        var font_size = parent.clientWidth / 50;

        this.chartSport.options = {
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (val, index,) {
                            return String.fromCharCode(sport_icons[keys[index]]);
                        },
                        font: {
                            family: "kmt-font",
                            size: font_size * 2,

                        },
                        color: '#33582F',
                        autoSkip: false,

                    }
                },
                x: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (val, index,) {
                            return round(val, DIGITS[type]) + " " + units[type];
                        },
                        font: {
                            size: font_size,
                        },
                    },
                }
            },
            maintainAspectRatio: false,
            animation: {
                duration: 0
            }

        }
        if (this.resize_timeout) clearTimeout(this.resize_timeout)
        this.resize_timeout = setTimeout(() => { resize(); }, 200);
        this.chartSport.resize();
        this.chartSport.update();
    }

    async download_each_tour() {

        document.getElementById("map-loading").style.display = "block";

        this.map.getSource("route").setData({
            "type": "MultiLineString",
            "coordinates": []
        });

        var tours = this.filter_on_sports(this.tours, this.sport_filters);

        // compute the mean position of each tour
        var lats = []
        var lngs = []
        for (var key in tours) {
            var tour = tours[key];
            lats.push(tour["lat"]);
            lngs.push(tour["lng"]);
        }
        lats = lats.sort();
        lngs = lngs.sort();
        var l = lats.length;

        if (l > 0) {
            var center = [lngs[Math.floor(l / 2)], lats[Math.floor(l / 2)]];
            var zoom = 12;

            if (this.first_pos) {
                // instant move to center
                this.map.flyTo({
                    center: center,
                    zoom: 2,
                    speed: 100
                });
            }
        }



        var multi_pos = []

        var interval = setInterval(() => {
            this.map.getSource("route").setData({
                "type": "MultiLineString",
                "coordinates": multi_pos
            });

            if (this.first_pos) {
                this.map.flyTo({
                    center: center,
                    zoom: zoom,
                    speed: 2
                });
                this.first_pos = false;
            }


        }, 5000);

        var keys = Object.keys(tours).reverse();
        for (let i = 0; i < keys.length; i++) {
            var key = keys[i];
            var tour = tours[key];
            var data = await getGPX(tour["id"]);

            var positions = []
            for (let i = 0; i < data.length; i++) {
                positions.push([data[i].lng, data[i].lat])
            }
            multi_pos.push(positions);
            if (i % 10 == 0)
                console.log(i + " / " + keys.length);
            document.getElementById("progress").style.width = ((i + 1) / keys.length * 100) + "%";
        }
        console.log(keys.length + " / " + keys.length);
        clearInterval(interval);
        this.map.getSource("route").setData({
            "type": "MultiLineString",
            "coordinates": multi_pos
        });
        if (this.first_pos) {
            this.map.flyTo({
                center: center,
                zoom: zoom,
                speed: 0.5
            });
            this.first_pos = false;
        }

        document.getElementById("map-loading").style.display = "none";

    }




    /////////////////////////////////////////////////////////////////////////////////
    // TOURS STATS
    /////////////////////////////////////////////////////////////////////////////////
    mean_distance(tours) {
        var sum = 0
        for (var key in tours) {
            sum += tours[key]["distance"]
        }
        return sum / Object.keys(tours).length
    }
    total_distance(tours) {
        var sum = 0
        for (var key in tours) {
            sum += tours[key]["distance"]
        }
        return sum
    }
    mean_duration(tours) {
        var sum = 0
        for (var key in tours) {
            sum += tours[key]["duration"]
        }
        return sum / Object.keys(tours).length
    }
    total_duration(tours) {
        var sum = 0
        for (var key in tours) {
            sum += tours[key]["duration"]
        }
        return sum
    }
    mean_elevation_up(tours) {
        var sum = 0
        for (var key in tours) {
            sum += tours[key]["elevation_up"]
        }
        return sum / Object.keys(tours).length
    }
    total_elevation_up(tours) {
        var sum = 0
        for (var key in tours) {
            sum += tours[key]["elevation_up"]
        }
        return sum
    }
    mean_elevation_down(tours) {
        var sum = 0
        for (var key in tours) {
            sum += tours[key]["elevation_down"]
        }
        return sum / Object.keys(tours).length
    }
    total_elevation_down(tours) {
        var sum = 0
        for (var key in tours) {
            sum += tours[key]["elevation_down"]
        }
        return sum
    }
    mean_time_in_motion(tours) {
        var sum = 0
        for (var key in tours) {
            var t = tours[key]["time_in_motion"]
            if (isNaN(t)) t = tours[key]["duration"]
            sum += t
        }
        return sum / Object.keys(tours).length
    }
    total_time_in_motion(tours) {
        var sum = 0
        for (var key in tours) {
            sum += nan_to_zero(tours[key]["time_in_motion"])
        }
        return sum
    }
    number_of_tours(tours) {
        return Object.keys(tours).length
    }

    filter_on_month(tours, month) {
        var filtered = {}
        for (var key in tours) {
            if (tours[key]["date"].getMonth() == month) {
                filtered[key] = tours[key]
            }
        }
        return filtered
    }
    filter_on_year(tours, year) {
        var filtered = {}
        for (var key in tours) {
            if (tours[key]["date"].getFullYear() == year) {
                filtered[key] = tours[key]
            }
        }
        return filtered
    }
    filter_on_sports(tours, sports) {
        if (sports.length == 0) return tours
        var filtered = {}
        for (var key in tours) {
            if (sports.includes(tours[key]["sport"])) {
                filtered[key] = tours[key]
            }
        }
        return filtered
    }
    filter_on_day_of_week(tours, day) {
        var filtered = {}
        for (var key in tours) {
            if (tours[key]["date"].getDay() == day) {
                filtered[key] = tours[key]
            }
        }
        return filtered
    }

    filter_after(tours, date) {
        var filtered = {}
        for (var key in tours) {
            if (tours[key]["date"] > date) {
                filtered[key] = tours[key]
            }
        }
        return filtered
    }
    filter_between(tours, date1, date2) {
        var filtered = {}
        for (var key in tours) {
            if (tours[key]["date"] > date1 && tours[key]["date"] < date2) {
                filtered[key] = tours[key]
            }
        }
        return filtered
    }


    list_sports(tours) {
        var sports = []
        for (var key in tours) {
            if (!sports.includes(tours[key]["sport"])) {
                sports.push(tours[key]["sport"])
            }
        }
        return sports
    }


    /////////////////////////////////////////////////////////////////////////////////
    // KOMOOT API
    /////////////////////////////////////////////////////////////////////////////////


    async searchUser(query: string) {
        query = query.trim();
        if (query == "") {
            let e = document.getElementById("username") as HTMLInputElement;
            e.value = this.komoot_id;
            return;
        }
        if (query == this.komoot_id) return;

        this.komoot_id = query;
        localStorage.setItem("komoot_id", query);
        var url = new URL(window.location.href);
        url.searchParams.set("id", this.komoot_id);
        window.history.pushState({}, '', url);
        await this.init();
    }




    filter_invalid_tours() {

        var overlaps = []
        var keeps = []
        var last_tour = ""
        var actual_overlap = ""

        for (var key in this.tours) {

            if (last_tour == "") {
                last_tour = key
                keeps.push(key)
                continue
            }

            var last_date = this.tours[last_tour]["date"]
            var last_end = new Date(last_date.getTime() + this.tours[last_tour]["duration"] * 60 * 60 * 1000)
            var actual_date = this.tours[key]["date"]
            var actual_end = new Date(actual_date.getTime() + this.tours[key]["duration"] * 60 * 60 * 1000)

            if (date_overlaps(last_date, last_end, actual_date, actual_end)) {
                if (actual_overlap == "")
                    actual_overlap = key
                overlaps.push(key)
            }
            else {
                actual_overlap = ""
                keeps.push(key)
            }
            last_tour = key
        }

        for (var key in overlaps) {
            delete this.tours[overlaps[key]]
        }
    }

    fullscreen() {
        var map = document.getElementById("map-container");
        map.classList.toggle("fullscreen");
        var is_fullscreen = map.classList.contains("fullscreen");
        document.getElementById("body").classList.toggle("hide-scroll")
        if (is_fullscreen) {
            document.getElementById("btn-expand").innerHTML = "close_fullscreen";
        }
        else {
            document.getElementById("btn-expand").innerHTML = "open_with";

        }


        setTimeout(() => {
            resize_map();
            this.map.resize();
        }, 100);
    }

    rotate_map(deg) {
        this.rotation += deg;
        this.map.rotateTo(this.rotation);
    }

}


const a = new app();
document.addEventListener('DOMContentLoaded', () => {
    // read URL param id
    var url = new URL(window.location.href);
    var komoot_id = url.searchParams.get("id");
    if (komoot_id) {
        a.komoot_id = komoot_id;
        document.getElementById("username").setAttribute("value", komoot_id);
        localStorage.setItem("komoot_id", komoot_id);
    }

    komoot_id = localStorage.getItem("komoot_id");
    if (komoot_id) {
        a.komoot_id = komoot_id;
        document.getElementById("username").setAttribute("value", komoot_id);
        // change URL
        var url = new URL(window.location.href);
        url.searchParams.set("id", komoot_id);
        window.history.pushState({}, '', url);

    }
    setTimeout(() => {
        cleanDB();
    }, 1000);
    a.init();
});

// on focus lost
document.getElementById("username").addEventListener("focusout", (e) => {
    let el = e.target as HTMLInputElement;
    a.searchUser(el.value);
});
document.getElementById("username").addEventListener("keypress", (e) => {
    if (e.key === 'Enter') {
        let el = e.target as HTMLInputElement;
        a.searchUser(el.value);
    }
});
document.getElementById("search-user").addEventListener("click", (e) => {
    let el = document.getElementById("username") as HTMLInputElement;
    a.searchUser(el.value);
});

document.getElementById("komoot-id").addEventListener("click", (e) => {
    document.getElementById("komoot-id-help").style.display = "block";
    setTimeout(() => {
        document.getElementById("komoot-id-help").style.display = "none";
    }, 5000);
});

document.getElementById("show-tours").addEventListener("click", (e) => {
    if (a.tour_list_opened) a.hideTours();
    else a.showTours();
});


function resize() {
    // default font size
    const max_width = 800
    var parent = a.chart30d.canvas.parentNode;
    var page_width = Math.min(window.innerWidth, max_width);
    var font_size = ratio(20, page_width, max_width);
    var dash_r = ratio(5, page_width, max_width);
    var dash = [dash_r, dash_r]
    var points = ratio(3, page_width, max_width);
    var lines = ratio(3, page_width, max_width);
    var bar_ratio_normal = ratio(2, page_width, max_width);
    var bar_ratio_small = ratio(1, page_width, max_width);
    var legend_box = ratio(40, page_width, max_width);


    a.chart30d.canvas.parentNode.style.height = parent.clientWidth * 0.66 + "px";
    a.chart30d.options.scales.y.ticks.font = { size: font_size };
    a.chart30d.options.scales.x.ticks.font = { size: font_size };
    a.chart30d.options.scales.x2.ticks.font = { size: font_size };
    a.chart30d.options.plugins.legend.labels.font = { size: font_size };
    a.chart30d.options.plugins.legend.labels.boxWidth = legend_box;
    a.chart30d.data.datasets[0].borderWidth = bar_ratio_small
    a.chart30d.data.datasets[1].borderWidth = bar_ratio_small
    a.chart30d.data.datasets[2].borderWidth = lines
    a.chart30d.data.datasets[2].borderDash = dash

    a.chart12m.canvas.parentNode.style.height = parent.clientWidth * 0.66 + "px";
    a.chart12m.options.scales.y.ticks.font = { size: font_size };
    a.chart12m.options.scales.x.ticks.font = { size: font_size };
    a.chart12m.options.plugins.legend.labels.font = { size: font_size };
    a.chart12m.options.plugins.legend.labels.boxWidth = legend_box;
    a.chart12m.data.datasets[0].borderWidth = lines
    a.chart12m.data.datasets[0].pointRadius = points
    a.chart12m.data.datasets[0].borderDash = dash
    a.chart12m.data.datasets[1].borderWidth = lines
    a.chart12m.data.datasets[1].borderDash = dash
    a.chart12m.data.datasets[2].borderWidth = bar_ratio_normal
    a.chart12m.data.datasets[3].borderWidth = bar_ratio_normal


    a.chartW.canvas.parentNode.style.height = parent.clientWidth * 0.66 + "px";
    a.chartW.options.scales.y.ticks.font = { size: font_size };
    a.chartW.options.scales.x.ticks.font = { size: font_size };
    a.chartW.options.plugins.legend.labels.font = { size: font_size };
    a.chartW.options.plugins.legend.labels.boxWidth = legend_box;
    a.chartW.data.datasets[0].borderWidth = bar_ratio_normal

    a.chartP.canvas.parentNode.style.height = parent.clientWidth * 0.66 + "px";
    a.chartP.options.scales.y.ticks.font = { size: font_size };
    a.chartP.options.scales.x.ticks.font = { size: font_size };
    a.chartP.options.plugins.legend.labels.font = { size: font_size };
    a.chartP.options.plugins.legend.labels.boxWidth = legend_box;
    if (a.chartP.data.datasets.length > 1) {
        a.chartP.data.datasets[0].borderWidth = lines
        a.chartP.data.datasets[1].borderWidth = lines
        a.chartP.data.datasets[2].borderWidth = bar_ratio_small
    } else {
        a.chartP.data.datasets[0].borderWidth = bar_ratio_small
    }


    a.chartSport.canvas.parentNode.style.height = parent.clientWidth * 0.4 * Math.max(Math.min(a.sports.length, 4), 1) / 4.0 + "px"
    a.chartSport.options.scales.y.ticks.font = { size: font_size * 1.8, family: "kmt-font" };
    a.chartSport.options.scales.x.ticks.font = { size: font_size };
    a.chartSport.options.plugins.legend.labels.font = { size: font_size };
    a.chartSport.options.plugins.legend.labels.boxWidth = legend_box;
    a.chartSport.data.datasets[0].borderWidth = bar_ratio_normal


    a.chart30d.update();
    a.chart12m.update();
    a.chartW.update();
    a.chartP.update();
    a.chartSport.update();


    resize_map();
}

function resize_map() {
    var fullscreen = document.getElementById("map-container").classList.contains("fullscreen");

    var window_width = window.innerWidth;
    var window_height = window.innerHeight;

    var map = document.getElementById("map");

    if (!fullscreen) {
        var w = 740, h = 500;
        var page_width = Math.min(window_width * 0.925, 740);
        var scale = page_width / w;
        var translate = (-(1 / scale - 1) * 50) + "%";


        map.style.transform = "scale(" + scale + ")";
        map.style.transformOrigin = "0 0";
    }
    else {
        if (window_width < 800) {
            var min_side = 800;
            var scale = Math.min(window_width / min_side, window_height / min_side);
            var w = window_width / scale;
            var h = window_height / scale;
            map.style.transform = "scale(" + scale + ")";
            map.style.width = w + "px";
            map.style.height = h + "px";

        }
        else {
            map.style.transform = "scale(1)";
            map.style.width = window_width + "px";
            map.style.height = window_height + "px";
        }
    }
}

// resize
window.addEventListener('resize', () => {
    if (a.resize_timeout) clearTimeout(a.resize_timeout)
    a.resize_timeout = setTimeout(() => { resize(); }, 200);
});

document.getElementById("btn-expand").addEventListener("click", (e) => {
    a.fullscreen();
});

document.getElementById("btn-rotate-left").addEventListener("click", (e) => {
    a.rotate_map(22.5);
});
document.getElementById("btn-rotate-right").addEventListener("click", (e) => {
    a.rotate_map(-22.5);
});