

export function round(value, decimals) {
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}
export function nan_to_zero(value) {
    if (isNaN(value)) {
        return 0
    }
    return value
}
export function zeroPad(num, numZeros) {
    var n = Math.abs(num);
    var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
    var zeroString = Math.pow(10, zeros).toString().substr(1);
    if (num < 0) {
        zeroString = '-' + zeroString;
    }

    return zeroString + n;
}
export function length(dict) {
    return Object.keys(dict).length;
}
export function to_string_group_by_3(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;");
}



export function add_event_listener_to_select(select, callback) {
    if (typeof select === "string") select = document.getElementById(select);
    for (let i = 0; i < select.children.length; i++) {
        select.children[i].addEventListener("click", (e) => {
            for (let j = 0; j < select.children.length; j++) {
                select.children[j].classList.remove("selected");
            }
            select.children[i].classList.add("selected");
            callback(i)
        });
    }
}




export function date_overlaps(Astart, Aend, Bstart, Bend) {
    return Astart.getTime() <= Bend.getTime() && Bstart.getTime() <= Aend.getTime();
}

var __multi_select__ = {}
export function add_event_listener_to_multi_select(select, callback) {
    if (typeof select === "string") select = document.getElementById(select);
    __multi_select__[select.id] = []
    for (let i = 0; i < select.children.length; i++) {
        __multi_select__[select.id].push(select.children[i].classList.contains("selected"));
        select.children[i].addEventListener("click", (e) => {
            select.children[i].classList.toggle("selected");
            __multi_select__[select.id][i] = !__multi_select__[select.id][i];
            callback(__multi_select__[select.id])
        });
    }
}

export function hours_to_hhHmmM(hours) {
    var h = Math.floor(hours);
    var m = Math.round((hours - h) * 60);
    if (m == 60) {
        h++;
        m = 0;
    }
    return zeroPad(h, 2) + "h " + zeroPad(m, 2) + "m";
}

export function hours_to_hhmm(hours) {
    var h = Math.floor(hours);
    var m = Math.round((hours - h) * 60);
    if (m == 60) {
        h++;
        m = 0;
    }
    return zeroPad(h, 2) + ":" + zeroPad(m, 2);
}

export function hours_to_mmss(hours) {
    var h = Math.floor(hours);
    hours = (hours - h) * 60;
    var m = Math.floor(hours);
    var s = Math.round((hours - m) * 60);
    if (s == 60) {
        m++;
        s = 0;
    }
    if (h > 0) {
        return zeroPad(h, 2) + ":" + zeroPad(m, 2) + ":" + zeroPad(s, 2);
    }
    return zeroPad(m, 2) + ":" + zeroPad(s, 2);
}

export function date_to_ddmmyyyy(date) {
    return zeroPad(date.getDate(), 2) + "/" + zeroPad(date.getMonth() + 1, 2) + "/" + date.getFullYear();
}
export function date_to_dayofweek_day_month_yyyy(date) {
    var days = ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."];
    var months = ["Jan.", "Fév.", "Mar.", "Avr.", "Mai", "Juin", "Juil.", "Août", "Sep.", "Oct.", "Nov.", "Déc."];
    return days[date.getDay()] + " " + zeroPad(date.getDate(), 2) + " " + months[date.getMonth()] + " " + date.getFullYear();
}

export function latlonDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = (lat2 - lat1) * Math.PI / 180;
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var lat1:any = lat1 * Math.PI / 180;
    var lat2:any = lat2 * Math.PI / 180;

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}

export function mobil_avg(x, WIN){
    var y = [...x]
    for (let i = 0; i < x.length; i++) {
        var sum = 0;
        var di = 0;
        if (i - WIN < 0) di = WIN - i;

        for (let j = 0; j < WIN; j++) {
            var index = i-j+di;
            sum += x[index];
        }
        y[i] = sum/WIN;
    }
    return y;
}

export function filterLocalMinMax(x, y) {
    var loc = findLocalMinMax(y);
    var res = [];

    for (let i = 0; i < loc.length; i++) {
        var index = loc[i];
        res.push({x: x[index], y: y[index]});
    }
    return res;
}

function findLocalMinMax(input, steps = 100, win = 20) {
    var sub = [[0, input.length]];
    var next_sub = [];


    var res = [0, input.length-1];

    while (steps > 0) {
        var [start, end] = sub.pop();
        var max_i = splitOnMax(input, start, end);
        var min_i = splitOnMin(input, start, end);
        
        res.push(max_i, min_i);

        var i1 = Math.min(max_i, min_i);
        var i2 = Math.max(max_i, min_i);

        if (i1 - start > win)
            next_sub.push([start, i1])
        if (i2 - i1 > win)
            next_sub.push([i1, i2])
        if (end - i2 > win)
            next_sub.push([i2, end])
        

        if (sub.length == 0) {
            sub = next_sub;
            next_sub = [];

            steps--;
            if (sub.length == 0) break;
        }
    }

    res.sort((a, b) => a - b);
    return res;
}

function splitOnMax(input, start, end){

    var length = end - start;
    var length_3 = Math.floor(length/6);
    var max_i = start;
    var max = input[start];

    for (let i = start+length_3; i < end-length_3; i++) {
        if (input[i] > max) {
            max = input[i];
            max_i = i;
        }
    }
    return max_i;
}

function splitOnMin(input, start, end){

    var length = end - start;
    var length_3 = Math.floor(length/6);
    var min_i = start;
    var min = input[start];

    for (let i = start+length_3; i < end-length_3; i++) {
        if (input[i] < min) {
            min = input[i];
            min_i = i;
        }
    }

    return min_i;
}

/**
 * Given two numbers, return -1, 0, or 1
 */
function compare(a, b) {
    if (a === b) {
        return 0;
    }
    if (a < b) {
        return -1;
    }
    return 1;
}

export function ratio(px, width, max_width) {
    if (width > max_width) {
        return px;
    }
    return px * width / max_width;
}


export function getsign(x){
    if (x == 0) return "";
    if (x > 0) return "+";
    return "-";
}